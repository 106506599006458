var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-top": "10px", height: "100%" } },
    [
      !_vm.personInfo || !_vm.personInfo.id
        ? _c(
            "div",
            {
              staticClass: "empty",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                "flex-direction": "column",
              },
            },
            [
              _c("img", {
                staticStyle: {
                  width: "300px",
                  height: "200px",
                  "margin-bottom": "10px",
                },
                attrs: { src: require("@/assets/sorry.png") },
              }),
              _c("span", { staticStyle: { color: "#c1c1c1" } }, [
                _vm._v("暂无数据"),
              ]),
            ]
          )
        : _c(
            "Form",
            {
              ref: "otherForm",
              staticClass: "otherForm",
              attrs: {
                "label-width": 110,
                rules: _vm.personInfoRule,
                model: _vm.personInfo,
              },
            },
            [
              _vm.personInfo.physicalType == "职业体检" ||
              _vm.personInfo.physicalType == "放射体检"
                ? _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      model: {
                        value: _vm.tabsValue,
                        callback: function ($$v) {
                          _vm.tabsValue = $$v
                        },
                        expression: "tabsValue",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "1", label: "基本信息" } },
                        [
                          _vm.tabsValue == "1"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "Row",
                                    { staticStyle: { "margin-top": "15px" } },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "工龄年数",
                                                prop: "workYear",
                                              },
                                            },
                                            [
                                              _c("InputNumber", {
                                                attrs: {
                                                  min: 0,
                                                  max: 100,
                                                  placeholder:
                                                    "请输入总工龄年数",
                                                },
                                                on: {
                                                  "on-blur": _vm.workYearChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo.workYear,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "workYear",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.workYear",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "工龄月数",
                                                prop: "workMonth",
                                              },
                                            },
                                            [
                                              _c("InputNumber", {
                                                attrs: {
                                                  min: 0,
                                                  max: 11,
                                                  placeholder: "请输入工龄月数",
                                                },
                                                on: {
                                                  "on-blur": _vm.workYearChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo.workMonth,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "workMonth",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.workMonth",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "结婚状况",
                                                prop: "isMarry",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    clearable: "",
                                                    transfer: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personInfo.isMarry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personInfo,
                                                        "isMarry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personInfo.isMarry",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: { value: "未婚" },
                                                    },
                                                    [_vm._v("未婚")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: { value: "已婚" },
                                                    },
                                                    [_vm._v("已婚")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: { value: "离异" },
                                                    },
                                                    [_vm._v("离异")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: { value: "丧偶" },
                                                    },
                                                    [_vm._v("丧偶")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: { value: "其他" },
                                                    },
                                                    [_vm._v("其他")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isInterrogationSplitting
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "8" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "接害年数",
                                                    prop: "exposureWorkYear",
                                                  },
                                                },
                                                [
                                                  _c("InputNumber", {
                                                    attrs: {
                                                      min: 0,
                                                      max: 100,
                                                      placeholder:
                                                        "请输入接害工龄年数",
                                                    },
                                                    on: {
                                                      "on-blur":
                                                        _vm.workYearChangeExp,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .exposureWorkYear,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "exposureWorkYear",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.exposureWorkYear",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "8" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "接害月数",
                                                    prop: "exposureWorkMonth",
                                                  },
                                                },
                                                [
                                                  _c("InputNumber", {
                                                    attrs: {
                                                      min: 0,
                                                      max: 11,
                                                      placeholder:
                                                        "请输入接害工龄月数",
                                                    },
                                                    on: {
                                                      "on-blur":
                                                        _vm.workYearChangeExp,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .exposureWorkMonth,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "exposureWorkMonth",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.exposureWorkMonth",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Row",
                                    [
                                      _vm.personInfo.workStateCode != "1001"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: "8" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "接害开始日期",
                                                    prop: "exposureStartDate",
                                                  },
                                                },
                                                [
                                                  _c("DatePicker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      type: "date",
                                                      placeholder: "请选择",
                                                      transfer: "",
                                                      options: _vm.option,
                                                      format: "yyyy-MM-dd",
                                                    },
                                                    on: {
                                                      "on-change":
                                                        _vm.exposureStartDateChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .exposureStartDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "exposureStartDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.exposureStartDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "文化程度" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    clearable: "",
                                                    transfer: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.personInfo.education,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.personInfo,
                                                        "education",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "personInfo.education",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.personInfoPriority,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "家庭地址",
                                                prop: "familyAddress",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                attrs: {
                                                  type: "text",
                                                  maxlength: 50,
                                                  placeholder: "请输入家庭地址",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo
                                                      .familyAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "familyAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.familyAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "工种名称",
                                                prop: "workTypeText",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                attrs: {
                                                  placeholder: "请选择工种",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleWorkCode.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo.workTypeText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "workTypeText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.workTypeText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: { label: "工种其他名称" },
                                            },
                                            [
                                              _c("Input", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "请输入工种其他名称",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo.workName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "workName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.workName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "8" } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "所属部门" } },
                                            [
                                              _c("Input", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "请输入部门名称",
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo.department,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "department",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.department",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.personInfo.physicalType == "放射体检" ||
                                  (_vm.personInfo.hazardFactors &&
                                    (_vm.personInfo.hazardFactors.indexOf(
                                      "160002"
                                    ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160501"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160502"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160503"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160504"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160506"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160507"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160999"
                                      ) > -1))
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "8" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "邮政编码" },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "请输入邮政编码",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo.zipCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "zipCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.zipCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "8" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "职务/职称" },
                                                },
                                                [
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder:
                                                        "请输入职务名称",
                                                    },
                                                    model: {
                                                      value: _vm.personInfo.job,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "job",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.job",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "2", label: "职业史和个人生活史" } },
                        [
                          _vm.tabsValue == "2"
                            ? _c(
                                "div",
                                { staticStyle: { "overflow-y": "auto" } },
                                [
                                  _vm.personInfo.sex == "女"
                                    ? _c(
                                        "Row",
                                        {
                                          staticStyle: { "margin-top": "15px" },
                                        },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "月经史" } },
                                            [
                                              _c(
                                                "Row",
                                                { attrs: { gutter: 15 } },
                                                [
                                                  _c(
                                                    "Col",
                                                    {
                                                      staticStyle: {
                                                        width: "225px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "RadioGroup",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .menstrualHistory,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "menstrualHistory",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.menstrualHistory",
                                                          },
                                                        },
                                                        [
                                                          _c("Radio", {
                                                            attrs: {
                                                              label: "正常",
                                                            },
                                                          }),
                                                          _c("Radio", {
                                                            attrs: {
                                                              label: "经期",
                                                            },
                                                          }),
                                                          _c("Radio", {
                                                            attrs: {
                                                              label: "停经",
                                                            },
                                                          }),
                                                          _c("Radio", {
                                                            attrs: {
                                                              label: "异常",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Col",
                                                    [
                                                      _vm.personInfo
                                                        .menstrualHistory ==
                                                      "异常"
                                                        ? _c("Input", {
                                                            directives: [
                                                              {
                                                                name: "width",
                                                                rawName:
                                                                  "v-width",
                                                                value: 292,
                                                                expression:
                                                                  "292",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .menstrualInfo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "menstrualInfo",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.menstrualInfo",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.isMenstrualHistory ||
                                              this.physicalType == "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Row",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "margin-top": "20px",
                                                      },
                                                      attrs: { gutter: 20 },
                                                    },
                                                    [
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .menarche,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "menarche",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.menarche",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("初潮")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("岁")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .period,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "period",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.period",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("经期")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("天")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .cycle,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "cycle",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.cycle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("周期")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("天")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .lastMenstruation,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "lastMenstruation",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.lastMenstruation",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "停经年龄"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("岁")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.personInfo.physicalType == "放射体检" ||
                                  (_vm.personInfo.hazardFactors &&
                                    (_vm.personInfo.hazardFactors.indexOf(
                                      "160002"
                                    ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160501"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160502"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160503"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160504"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160506"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160507"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160999"
                                      ) > -1))
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "婚姻史" } },
                                            [
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "margin-bottom": "24px",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("结婚日期："),
                                                  ]),
                                                  _c("DatePicker", {
                                                    staticStyle: {
                                                      width: "223px",
                                                    },
                                                    attrs: {
                                                      type: "date",
                                                      placeholder: "请选择",
                                                      transfer: "",
                                                      format: "yyyy-MM-dd",
                                                    },
                                                    on: {
                                                      "on-change":
                                                        _vm.marriageDateChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .marriageDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "marriageDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.marriageDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "24px",
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "配偶接触放射线情况："
                                                    ),
                                                  ]),
                                                  _c("Input", {
                                                    directives: [
                                                      {
                                                        name: "width",
                                                        rawName: "v-width",
                                                        value: 500,
                                                        expression: "500",
                                                      },
                                                    ],
                                                    attrs: {
                                                      maxlength: "50",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .spouseRadiationSituation,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "spouseRadiationSituation",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.spouseRadiationSituation",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "配偶职业及健康状况："
                                                    ),
                                                  ]),
                                                  _c("Input", {
                                                    directives: [
                                                      {
                                                        name: "width",
                                                        rawName: "v-width",
                                                        value: 500,
                                                        expression: "500",
                                                      },
                                                    ],
                                                    attrs: {
                                                      maxlength: "50",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.personInfo
                                                          .spouseHealthSituation,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.personInfo,
                                                          "spouseHealthSituation",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "personInfo.spouseHealthSituation",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.personInfo.sex == "男"
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "生育史" } },
                                            [
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "24px",
                                                  },
                                                  attrs: { gutter: 15 },
                                                },
                                                [
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          staticClass:
                                                            "sexExisting",
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "child",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .existingChildren,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "existingChildren",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.existingChildren",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("现有子女")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("人")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.personInfo.physicalType ==
                                                "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Row",
                                                    { attrs: { gutter: 15 } },
                                                    [
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "boys",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .boys,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "boys",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.boys",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "现有男孩"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("人")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "7" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "出生日期："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .boysBirth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "boysBirth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.boysBirth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "girls",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .girls,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "girls",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.girls",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "现有女孩"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("人")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "7" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "出生日期："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .girlsBirth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "girlsBirth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.girlsBirth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.personInfo.sex == "女"
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "生育史" } },
                                            [
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "24px",
                                                  },
                                                  attrs: { gutter: 15 },
                                                },
                                                [
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "child",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .existingChildren,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "existingChildren",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.existingChildren",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("现有子女")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("人")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "abortion",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .abortion,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "abortion",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.abortion",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("流产")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("次")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "premature",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .premature,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "premature",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.premature",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("早产")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("次")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "death",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .death,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "death",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.death",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("死亡")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("次")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: "4" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "abnormalFetus",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .abnormalFetus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "abnormalFetus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.abnormalFetus",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("异常胎")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("次")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.personInfo.physicalType ==
                                                "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Row",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "24px",
                                                      },
                                                      attrs: { gutter: 15 },
                                                    },
                                                    [
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "pregnancyCount",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .pregnancyCount,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "pregnancyCount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.pregnancyCount",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("孕次")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("次")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "liveBirth",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .liveBirth,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "liveBirth",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.liveBirth",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("活产")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("次")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "abortionSmall",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .abortionSmall,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "abortionSmall",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.abortionSmall",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "自然流产"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("次")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "multiparous",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .multiparous,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "multiparous",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.multiparous",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [_vm._v("多胎")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("次")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "7" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "不孕不育原因："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .infertilityReason,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "infertilityReason",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.infertilityReason",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.personInfo.physicalType ==
                                                "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Row",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "24px",
                                                      },
                                                      attrs: { gutter: 15 },
                                                    },
                                                    [
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "boys",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .boys,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "boys",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.boys",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "现有男孩"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("人")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "7" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "出生日期："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .boysBirth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "boysBirth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.boysBirth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          attrs: { span: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "Input",
                                                            {
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.existChildChange(
                                                                      "girls",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.personInfo
                                                                    .girls,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.personInfo,
                                                                      "girls",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "personInfo.girls",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "prepend",
                                                                  },
                                                                  slot: "prepend",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "现有女孩"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  attrs: {
                                                                    slot: "append",
                                                                  },
                                                                  slot: "append",
                                                                },
                                                                [_vm._v("人")]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "7" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "出生日期："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .girlsBirth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "girlsBirth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.girlsBirth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.personInfo.physicalType ==
                                                "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Row",
                                                    { attrs: { gutter: 15 } },
                                                    [
                                                      _c(
                                                        "Col",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                          attrs: { span: "10" },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "子女健康状况："
                                                            ),
                                                          ]),
                                                          _c("Input", {
                                                            attrs: {
                                                              maxlength: "50",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.personInfo
                                                                  .childrensHealth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.personInfo,
                                                                    "childrensHealth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "personInfo.childrensHealth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "过敏史" } },
                                        [
                                          _c(
                                            "Row",
                                            [
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    width: "85px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "RadioGroup",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .allergies,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "allergies",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.allergies",
                                                      },
                                                    },
                                                    [
                                                      _c("Radio", {
                                                        attrs: { label: "无" },
                                                      }),
                                                      _c("Radio", {
                                                        attrs: { label: "有" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                [
                                                  _vm.personInfo.allergies ==
                                                  "有"
                                                    ? _c("Input", {
                                                        directives: [
                                                          {
                                                            name: "width",
                                                            rawName: "v-width",
                                                            value: 433,
                                                            expression: "433",
                                                          },
                                                        ],
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.personInfo
                                                              .allergiesInfo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.personInfo,
                                                              "allergiesInfo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "personInfo.allergiesInfo",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "烟酒史" } },
                                        [
                                          _c(
                                            "Row",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "24px",
                                              },
                                              attrs: { gutter: 15 },
                                            },
                                            [
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    width: "225px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "RadioGroup",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .smokeState,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "smokeState",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.smokeState",
                                                      },
                                                    },
                                                    [
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "不吸烟",
                                                        },
                                                      }),
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "偶尔吸",
                                                        },
                                                      }),
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "经常吸",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: "5" } },
                                                [
                                                  _c(
                                                    "Input",
                                                    {
                                                      attrs: { type: "number" },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.existChildChange(
                                                            "packageEveryDay",
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .packageEveryDay,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "packageEveryDay",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.packageEveryDay",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "append",
                                                          },
                                                          slot: "append",
                                                        },
                                                        [_vm._v("支每天")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: "5" } },
                                                [
                                                  _c(
                                                    "Input",
                                                    {
                                                      attrs: { type: "number" },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.existChildChange(
                                                            "smokeYear",
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .smokeYear,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "smokeYear",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.smokeYear",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "prepend",
                                                          },
                                                          slot: "prepend",
                                                        },
                                                        [_vm._v("共")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "append",
                                                          },
                                                          slot: "append",
                                                        },
                                                        [_vm._v("年")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              this.physicalType == "放射体检" ||
                                              (_vm.personInfo.hazardFactors &&
                                                (_vm.personInfo.hazardFactors.indexOf(
                                                  "160002"
                                                ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160501"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160502"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160503"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160504"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160506"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160507"
                                                  ) > -1 ||
                                                  _vm.personInfo.hazardFactors.indexOf(
                                                    "160999"
                                                  ) > -1))
                                                ? _c(
                                                    "Col",
                                                    { attrs: { span: "5" } },
                                                    [
                                                      _c(
                                                        "Input",
                                                        {
                                                          attrs: {
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.existChildChange(
                                                                "quitSomking",
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.personInfo
                                                                .quitSomking,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.personInfo,
                                                                "quitSomking",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "personInfo.quitSomking",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "prepend",
                                                              },
                                                              slot: "prepend",
                                                            },
                                                            [_vm._v("戒烟")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "append",
                                                              },
                                                              slot: "append",
                                                            },
                                                            [_vm._v("年")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Row",
                                            { attrs: { gutter: 15 } },
                                            [
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    width: "225px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "RadioGroup",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .drinkState,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "drinkState",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.drinkState",
                                                      },
                                                    },
                                                    [
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "不饮酒",
                                                        },
                                                      }),
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "偶饮酒",
                                                        },
                                                      }),
                                                      _c("Radio", {
                                                        attrs: {
                                                          label: "经常饮",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: "5" } },
                                                [
                                                  _c(
                                                    "Input",
                                                    {
                                                      attrs: { type: "number" },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.existChildChange(
                                                            "mlEveryDay",
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .mlEveryDay,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "mlEveryDay",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.mlEveryDay",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "append",
                                                          },
                                                          slot: "append",
                                                        },
                                                        [_vm._v("ml/天")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: "5" } },
                                                [
                                                  _c(
                                                    "Input",
                                                    {
                                                      attrs: { type: "number" },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.existChildChange(
                                                            "drinkYear",
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.personInfo
                                                            .drinkYear,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.personInfo,
                                                            "drinkYear",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "personInfo.drinkYear",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "prepend",
                                                          },
                                                          slot: "prepend",
                                                        },
                                                        [_vm._v("共")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "append",
                                                          },
                                                          slot: "append",
                                                        },
                                                        [_vm._v("年")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "出生地" } },
                                        [
                                          _c("v-region", {
                                            staticStyle: { width: "520px" },
                                            attrs: {
                                              type: "group",
                                              town: true,
                                              transfer: "",
                                            },
                                            on: { values: _vm.regionChange },
                                            model: {
                                              value: _vm.birthplaceCode,
                                              callback: function ($$v) {
                                                _vm.birthplaceCode = $$v
                                              },
                                              expression: "birthplaceCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "家族史" } },
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                              },
                                              attrs: { span: "24" },
                                            },
                                            [
                                              _c(
                                                "Button",
                                                {
                                                  staticStyle: {
                                                    position: "absolute",
                                                    right: "-4px",
                                                    bottom: "1px",
                                                    "z-index": "8",
                                                  },
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleWordChoose()
                                                    },
                                                  },
                                                },
                                                [_vm._v("选 ")]
                                              ),
                                              _c("Input", {
                                                directives: [
                                                  {
                                                    name: "width",
                                                    rawName: "v-width",
                                                    value: 520,
                                                    expression: "520",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "textarea",
                                                  placeholder:
                                                    "家庭中有无遗传性疾病、血液病、糖尿病、高血压病、神经精神性疾病、肿瘤以及结核、肝炎等传染性疾病",
                                                  rows: 3,
                                                },
                                                model: {
                                                  value:
                                                    _vm.personInfo
                                                      .familyHistory,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personInfo,
                                                      "familyHistory",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personInfo.familyHistory",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.personInfo.physicalType == "放射体检" ||
                                  (_vm.personInfo.hazardFactors &&
                                    (_vm.personInfo.hazardFactors.indexOf(
                                      "160002"
                                    ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160501"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160502"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160503"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160504"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160506"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160507"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160999"
                                      ) > -1))
                                    ? _c(
                                        "Row",
                                        {
                                          staticStyle: { "margin-top": "15px" },
                                        },
                                        [
                                          _vm.status == 0
                                            ? _c(
                                                "Button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "15px",
                                                  },
                                                  attrs: {
                                                    type: "info",
                                                    icon: "md-add-circle",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleAddClick(
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("新增")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "red",
                                                height: "30px",
                                                "line-height": "30px",
                                              },
                                            },
                                            [_vm._v("放射工种职业历史记录")]
                                          ),
                                          _c("Table", {
                                            ref: "table",
                                            attrs: {
                                              border: "",
                                              columns:
                                                _vm.careerHistoryColumns1,
                                              sortable: "custom",
                                              data: _vm.careerHistoryData1,
                                              "max-height": 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "action",
                                                  fn: function ({
                                                    row,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            type: "error",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                row,
                                                                index,
                                                                1
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("Icon", {
                                                            attrs: {
                                                              type: "md-trash",
                                                            },
                                                          }),
                                                          _vm._v(" 删除 "),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3744995594
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.personInfo.physicalType == "职业体检" &&
                                  !(
                                    _vm.personInfo.hazardFactors &&
                                    (_vm.personInfo.hazardFactors.indexOf(
                                      "160002"
                                    ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160501"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160502"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160503"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160504"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160506"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160507"
                                      ) > -1 ||
                                      _vm.personInfo.hazardFactors.indexOf(
                                        "160999"
                                      ) > -1)
                                  )
                                    ? _c(
                                        "Row",
                                        {
                                          staticStyle: { "margin-top": "15px" },
                                        },
                                        [
                                          _vm.status == 0
                                            ? _c(
                                                "Button",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "15px",
                                                  },
                                                  attrs: {
                                                    type: "info",
                                                    icon: "md-add-circle",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleAddClick(
                                                        2
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("新增")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "red",
                                                height: "30px",
                                                "line-height": "30px",
                                              },
                                            },
                                            [_vm._v("非放射工种职业历史记录")]
                                          ),
                                          _c("Table", {
                                            ref: "table",
                                            attrs: {
                                              border: "",
                                              columns:
                                                _vm.careerHistoryColumns2,
                                              sortable: "custom",
                                              data: _vm.careerHistoryData2,
                                              "max-height": 250,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "action",
                                                  fn: function ({
                                                    row,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            type: "error",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                row,
                                                                index,
                                                                2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("Icon", {
                                                            attrs: {
                                                              type: "md-trash",
                                                            },
                                                          }),
                                                          _vm._v(" 删除 "),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              970601737
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { name: "4", label: "既往患病史" } },
                        [
                          _vm.tabsValue == "4"
                            ? _c(
                                "Row",
                                { staticStyle: { "margin-top": "15px" } },
                                [
                                  _vm.status == 0
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "15px",
                                          },
                                          attrs: {
                                            type: "info",
                                            icon: "md-add-circle",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.tableAddItem.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("新增")]
                                      )
                                    : _vm._e(),
                                  _c("Table", {
                                    ref: "table",
                                    attrs: {
                                      border: "",
                                      columns: _vm.pastMedicalHistoryColumns,
                                      sortable: "custom",
                                      data: _vm.personInfo
                                        .pastMedicalHistoryData,
                                      "max-height": 500,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "action",
                                          fn: function ({ row, index }) {
                                            return [
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    type: "error",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.tableDeleteItem(
                                                        row,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: { type: "md-trash" },
                                                  }),
                                                  _vm._v(" 删除 "),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      671694038
                                    ),
                                  }),
                                  _c(
                                    "FormItem",
                                    {
                                      staticStyle: {
                                        "margin-top": "15px",
                                        width: "100%",
                                      },
                                      attrs: {
                                        label: "其他",
                                        "label-width": 40,
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: { type: "textarea", rows: 3 },
                                        model: {
                                          value:
                                            _vm.personInfo
                                              .pastMedicalHistoryOtherInfo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.personInfo,
                                              "pastMedicalHistoryOtherInfo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "personInfo.pastMedicalHistoryOtherInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.personInfo.physicalType == "职业体检" &&
                      !(
                        _vm.personInfo.hazardFactors &&
                        (_vm.personInfo.hazardFactors.indexOf("160002") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160501") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160502") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160503") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160504") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160506") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160507") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160999") > -1)
                      )
                        ? _c(
                            "el-tab-pane",
                            { attrs: { name: "5", label: "症状询问" } },
                            [
                              _vm.tabsValue == "5"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "margin-top": "15px",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "12" } },
                                            [
                                              _c("Table", {
                                                ref: "table",
                                                attrs: {
                                                  border: "",
                                                  columns: _vm.symptomColumns,
                                                  data: _vm.symptomData1,
                                                  "max-height":
                                                    _vm.tableMaxHeight,
                                                  "row-class-name":
                                                    _vm.rowClassName,
                                                  width: "100%",
                                                  "span-method":
                                                    _vm.handleSpan1,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "12" } },
                                            [
                                              _c("Table", {
                                                ref: "table1",
                                                attrs: {
                                                  border: "",
                                                  columns: _vm.symptomColumns2,
                                                  data: _vm.symptomData2,
                                                  "row-class-name":
                                                    _vm.rowClassName,
                                                  "max-height":
                                                    _vm.tableMaxHeight,
                                                  width: "100%",
                                                  "span-method":
                                                    _vm.handleSpan2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("备注")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "text-indent": "25px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "1：无症状以“——”表示；有症状以“+”表示；症状程度：偶有以“±”，较轻以“+”，中等以“++”，明显以“+++”表示"
                                              ),
                                            ]
                                          ),
                                          _vm.askProjectData &&
                                          _vm.askProjectData.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "text-indent": "25px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 2：危害因素对应的主要询问项目为："
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.askProjectData.join(
                                                            ","
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm.personInfo.physicalType == "放射体检" ||
                      (_vm.personInfo.hazardFactors &&
                        (_vm.personInfo.hazardFactors.indexOf("160002") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160501") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160502") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160503") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160504") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160506") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160507") > -1 ||
                          _vm.personInfo.hazardFactors.indexOf("160999") > -1))
                        ? _c(
                            "el-tab-pane",
                            { attrs: { name: "6", label: "自觉症状" } },
                            [
                              _vm.tabsValue == "6"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "margin-top": "15px",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "24" } },
                                            [
                                              _vm.status == 0
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "15px",
                                                      },
                                                      attrs: {
                                                        type: "info",
                                                        icon: "md-add-circle",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.symptomDataAdd()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("新增")]
                                                  )
                                                : _vm._e(),
                                              _c("Table", {
                                                ref: "table",
                                                attrs: {
                                                  border: "",
                                                  columns: _vm.symptomFsColumns,
                                                  data: _vm.symptomData,
                                                  "max-height":
                                                    _vm.tableMaxHeight,
                                                  width: "100%",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "action",
                                                      fn: function ({
                                                        row,
                                                        index,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "Button",
                                                            {
                                                              attrs: {
                                                                type: "error",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.symptomDataDelete(
                                                                      row,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("Icon", {
                                                                attrs: {
                                                                  type: "md-trash",
                                                                },
                                                              }),
                                                              _vm._v(" 删除 "),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  282584108
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("备注")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "text-indent": "25px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "症状程度：偶有以“±”，较轻以“+”，中等以“++”，明显以“+++”表示"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.personInfo.physicalType == "从业体检"
                ? _c(
                    "el-tabs",
                    { attrs: { type: "border-card" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "既往病史" } },
                        [
                          _c("Table", {
                            ref: "table",
                            attrs: {
                              columns: _vm.pastMedicalColumns,
                              data: _vm.personInfo.pastMedicalHistoryData,
                              "max-height": _vm.tableMaxHeight,
                              width: "100%",
                              border: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "Row",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "Col",
                    { attrs: { span: "10" } },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "检查医生",
                                    "label-width": 90,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.personInfo.wzCheckAutograph &&
                                        _vm.personInfo.isWzCheck == 1
                                          ? _vm.personInfo.wzCheckAutograph.indexOf(
                                              "/dcm"
                                            ) > -1
                                            ? _vm.personInfo.wzCheckAutograph
                                            : "data:image/png;base64," +
                                              _vm.personInfo.wzCheckAutograph
                                          : _vm.doctorInfo.autograph,
                                      width: "150",
                                      height: "30",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "检查日期",
                                    "label-width": 90,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.personInfo.wzCheckTime ||
                                          _vm.checkDate
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.personInfo.physicalType == "从业体检"
                    ? _c(
                        "Col",
                        { attrs: { span: "5" } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "民族" } },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    transfer: "",
                                  },
                                  model: {
                                    value: _vm.personInfo.nation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.personInfo, "nation", $$v)
                                    },
                                    expression: "personInfo.nation",
                                  },
                                },
                                _vm._l(_vm.nationArr, function (item, i) {
                                  return _c(
                                    "Option",
                                    { attrs: { value: item.value } },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "Col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { span: "8" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.status == 0
                            ? _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.handelSubmit },
                                },
                                [_vm._v("保存信息")]
                              )
                            : _vm._e(),
                          _vm.status == 0 || _vm.status == 1
                            ? _c(
                                "Button",
                                {
                                  attrs: { type: "info" },
                                  on: { click: _vm.handleViewTemplate },
                                },
                                [_vm._v("报告预览")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.personInfo.physicalType == "职业体检" &&
              !(
                _vm.personInfo.hazardFactors &&
                (_vm.personInfo.hazardFactors.indexOf("160002") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160501") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160502") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160503") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160504") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160506") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160507") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160999") > -1)
              )
                ? _c("preview-template", {
                    attrs: {
                      "physical-type": "职业体检",
                      personInfo: _vm.personInfo,
                      TTemplateId: "1385e587148decd3be7730602748e959",
                    },
                    model: {
                      value: _vm.previewShow,
                      callback: function ($$v) {
                        _vm.previewShow = $$v
                      },
                      expression: "previewShow",
                    },
                  })
                : _vm._e(),
              _vm.personInfo.physicalType == "放射体检" ||
              (_vm.personInfo.hazardFactors &&
                (_vm.personInfo.hazardFactors.indexOf("160002") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160501") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160502") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160503") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160504") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160506") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160507") > -1 ||
                  _vm.personInfo.hazardFactors.indexOf("160999") > -1))
                ? _c("preview-template", {
                    attrs: {
                      "physical-type": "放射体检",
                      personInfo: _vm.personInfo,
                      TTemplateId: "ab9cc40360eccb783ea5c5e0e5b98777",
                    },
                    model: {
                      value: _vm.previewShow,
                      callback: function ($$v) {
                        _vm.previewShow = $$v
                      },
                      expression: "previewShow",
                    },
                  })
                : _vm._e(),
              _vm.personInfo.physicalType == "从业体检"
                ? _c("preview-template", {
                    attrs: {
                      "physical-type": "从业体检",
                      personInfo: _vm.personInfo,
                      TTemplateId: "5ec68102779a2ccdb0eea03b24ef0b19",
                    },
                    model: {
                      value: _vm.previewShow,
                      callback: function ($$v) {
                        _vm.previewShow = $$v
                      },
                      expression: "previewShow",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("chooseWorkType", {
        on: { workCodeReback: _vm.workCodeReback },
        model: {
          value: _vm.workShow,
          callback: function ($$v) {
            _vm.workShow = $$v
          },
          expression: "workShow",
        },
      }),
      _c("harmDrawer", {
        attrs: {
          selectedHarmList: _vm.hazardFactorsSelect,
          selectType: "checkbox",
        },
        on: { harmReback: _vm.hazardFactorsReback },
        model: {
          value: _vm.hazardFactorsShow,
          callback: function ($$v) {
            _vm.hazardFactorsShow = $$v
          },
          expression: "hazardFactorsShow",
        },
      }),
      _c("fsDrawer", {
        attrs: { selectedList: _vm.fsSelectList },
        on: { fsReback: _vm.fsDrawerShowReback },
        model: {
          value: _vm.fsDrawerShow,
          callback: function ($$v) {
            _vm.fsDrawerShow = $$v
          },
          expression: "fsDrawerShow",
        },
      }),
      _c("drawer", {
        attrs: {
          modalTitle: "家族史",
          type: "jzs",
          isSearchPositive: false,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          officeId: "1454369800754171904",
          hazardFactors:
            _vm.personInfo.hazard_factors || _vm.personInfo.hazardFactors,
          hazardFactorsText:
            _vm.personInfo.hazard_factors_text ||
            _vm.personInfo.hazardFactorsText,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.wordChooseShow,
          callback: function ($$v) {
            _vm.wordChooseShow = $$v
          },
          expression: "wordChooseShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }